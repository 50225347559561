<template>
  <div>
    <BaseBreadcrumb
      :title="page.title"
      :icon="page.icon"
      :breadcrumbs="breadcrumbs"
    ></BaseBreadcrumb>
    <v-list-item-subtitle class="text-wrap"> </v-list-item-subtitle>
    <div class="mt-4">
      <v-card-title>
        <v-spacer></v-spacer>
      </v-card-title>
      <v-row>
        <v-col> </v-col>
        <v-col>
          <template>
            <div>
              <v-card>
                <v-responsive :aspect-ratio="16 / 9">
                  <v-card-text>
                    PVW
                  </v-card-text>
                </v-responsive>
              </v-card>
            </div>
          </template>
        </v-col>

        <v-col>
          <template>
            <div>
              <v-card>
                <v-responsive :aspect-ratio="16 / 9">
                  <v-card-text>
                    PGM
                  </v-card-text>
                </v-responsive>
              </v-card>
            </div>
          </template>
        </v-col>
        <v-col> </v-col>
      </v-row>
      <v-spacer></v-spacer>
      <template>
        <v-item-group mandatory>
          <v-container>
            <v-row>
              <v-col v-for="n in 6" :key="n" cols="12" md="2">
                <v-item v-slot="{ active, toggle }">
                  <v-card
                    :color="active ? 'primary' : ''"
                    class="d-flex align-center"
                    dark
                    multiple="selected"
                    height="100"
                    @click="toggle"
                    width="180"
                  >
                    <v-scroll-y-transition>
                      <div
                        v-if="active"
                        class="display-1 flex-grow-1 text-center"
                      >
                        Source {{ n }}
                      </div>
                    </v-scroll-y-transition>
                  </v-card>
                </v-item>
              </v-col>
            </v-row>
          </v-container>
        </v-item-group>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: "av_control_panel",
  data: () => ({
    page: {
      title: "AV Control Panel"
    },
    breadcrumbs: [
      {
        text: "Games",
        disabled: true
      },
      {
        text: "AV Control",
        disabled: true
      }
    ],
    selected: []
  })
};
</script>

<style></style>
