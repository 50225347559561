<template>
  <div>
    <template>
      <div>
        <v-row justify="center">
          <v-col style="width: 350px; flex: 0 1 auto;">
            <h2>Date:</h2>
            <v-date-picker
              v-model="schedule.date"
              @change="$emit('time_change', schedule)"
            ></v-date-picker>
          </v-col>
          <v-col style="width: 350px; flex: 0 1 auto;">
            <h2>Start Time:</h2>
            <v-time-picker
              v-model="schedule.start"
              :max="schedule.end"
              @input="$emit('time_change', schedule)"
            ></v-time-picker>
          </v-col>
          <v-col style="width: 350px; flex: 0 1 auto;">
            <h2>End Time:</h2>
            <v-time-picker
              v-model="schedule.end"
              :min="schedule.start"
              @input="$emit('time_change', schedule)"
            ></v-time-picker>
          </v-col>
        </v-row>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  data() {
    return {
      schedule: {
        date: new Date().toISOString().substr(0, 10),
        start: "",
        end: ""
      }
    };
  },
  watch: {
    schedule(newValue, oldValue) {
      this.$emit("time_change", newValue);
    }
  }
};
</script>

<style lang="scss" scoped></style>
