<template>
  <div>
    <v-list-item-subtitle class="text-wrap"></v-list-item-subtitle>
    <div class="mt-4">
      <v-divider></v-divider>
      <v-row>
        <v-btn elevation="2" @click="confirm_delete_all"
          >Delete All Posts</v-btn
        >
        <v-btn elevation="2" @click="confirm_remove_all_votes"
          >Remove All Votes</v-btn
        >
      </v-row>
      <AdminLayoutTable
        table_title="Users"
        :headers="headerProps"
        :items="all_posts_computed"
        v-on:edit_item="edit_item"
        v-on:delete_item="confirm_delete"
        item_key="id"
      >
        <template v-slot:importer>
          <csv-importer-view
            button-title="Create Post"
            item-type="Post"
            v-on:save-import="saveImport"
            :import_headers="import_headers"
          >
            <template v-slot:singleItemImporter> </template>
          </csv-importer-view>
        </template>
      </AdminLayoutTable>

      <confirmation-dialog ref="confirm" />
    </div>
  </div>
</template>

<script>
import AdminLayoutTable from "@/components/AdminLayoutTable";
import CSVImporterView from "@/components/csvData/CSVImporterView";
import generic_editor from "@/components/generic_editor";
import * as social_feed_utils from "../../modules/firebase_social_stream_utilities";
import { mapState } from "vuex";
export default {
  //headers are supplied as a list of objects
  //Text is the display name/text is mapped to the item(s) key { text: "name", value: "name" },

  name: "AdminSocialStreamManager",
  components: {
    AdminLayoutTable,
    "csv-importer-view": CSVImporterView,

    // point_creator_vt,
    // user_creator
    confirmationDialog: () => import("@/components/confirmationDialog")
  },
  props: {},
  data() {
    return {
      selected: [],
      dialog: false,
      formTitle: "My form title",
      tab: "tab data",
      email_search: "",
      last_name_search: "",
      headerProps: [
        { text: "First Name", value: "user_data.first_name" },
        { text: "Last Name", value: "user_data.last_name" }
      ],
      search: "",
      editedItem: {},
      items_per_page: 20,
      sort_key: "last_name",
      selected_index: -1,
      selected_user_for_edit: null,
      last_seen: null,
      import_headers: [
        {
          slug: "event_attendee_id",
          required: true,
          tooltip: `e.g. 12748127
          The Eventfinity Attendee ID for this user. This requires valid EF attendees.
          `
        },
        {
          slug: "event_email",
          required: true
        },

        {
          slug: "first_name",
          required: true
        },
        {
          slug: "last_name",
          required: true
        }
      ],
      social_stream_id: null,
      all_posts: []
    };
  },
  methods: {
    async confirm_delete_all() {
      if (
        await this.$refs.confirm.open(
          "Confirm",
          "Are you sure you want to delete all posts?"
        )
      ) {
        await social_feed_utils.delete_posts(
          this.client_selection,
          this.social_stream_id
        );
      }
    },
    async confirm_remove_all_votes() {
      if (
        await this.$refs.confirm.open(
          "Confirm",
          "Are you sure you want to remove all votes from all users?"
        )
      ) {
        await social_feed_utils.remove_votes_from_all_users(
          this.client_selection,
          this.social_stream_id
        );
      }
    },
    async confirm_delete(item, selection) {
      if (
        await this.$refs.confirm.open(
          "Confirm",
          "Are you sure you want to delete this record?"
        )
      ) {
        this.delete_item(item, selection);
      }
    },
    async edit_item(item) {},
    async saveImport(item) {}
  },
  computed: {
    all_posts_computed() {
      return this.all_posts;
    },
    items() {
      return this.$store.state.user_list;
    },
    ...mapState(["client_selection"])
  },
  async created() {
    this.$store.watch(
      state => {
        return this.$store.state.client_selection;
      },
      (newValue, oldValue) => {},
      {
        deep: true
      }
    );
    const route_params = this.$route.params;
    this.social_stream_id = encodeURIComponent(route_params.id);
    const all_posts = await social_feed_utils.fetch_posts(
      this.client_selection,
      this.social_stream_id
    );
    this.all_posts = all_posts;
  }
};
</script>
<style scoped></style>
