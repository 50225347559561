<template>
  <div>
    <BaseBreadcrumb
      :title="page.title"
      :icon="page.icon"
      :breadcrumbs="breadcrumbs"
    ></BaseBreadcrumb>
    <v-list-item-subtitle class="text-wrap"> </v-list-item-subtitle>
    <div class="mt-4">
      <v-card-title>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="activeLocations"
        sort-by="display_name"
        class="border"
        :search="search"
      >
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-toolbar-title></v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-dialog v-model="dialog" :max-width="max_widths[tab]">
              <template v-slot:activator="{ on }">
                <room-selector
                  :location-list="allLocations"
                  :activeSelection="activeLocations"
                  :target="FBPathname"
                  v-on="on"
                >
                </room-selector>
              </template>
              <v-card>
                <v-card-title>
                  <span class="headline">{{ formTitle }}</span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-tabs v-model="tab">
                      <v-tab>Game Settings</v-tab>
                      <v-tab>Clip Editor</v-tab>
                    </v-tabs>
                    <v-tabs-slider color="yellow"></v-tabs-slider>

                    <v-tabs-items v-model="tab">
                      <v-tab-item>
                        <v-row>
                          <v-col cols="6" sm="12" md="6">
                            <v-switch
                              v-model="editedItem.enabled"
                              label="Game Enabled"
                            ></v-switch>
                          </v-col>
                          <v-col cols="6" sm="12" md="6">
                            <v-btn elevation="2" @click="saveData"
                              >Download Analytics</v-btn
                            >
                          </v-col>
                          <v-col cols="6" sm="12" md="6">
                            <v-btn
                              elevation="2"
                              @click="open_editor = !open_editor"
                              >{{ !open_editor ? "Open" : "Close" }} Clip
                              Edtior</v-btn
                            >
                          </v-col>
                          <v-col cols="12" sm="12" md="12">
                            <!-- <v-switch
                          v-model="editedItem.moderated"
                          label="Moderated"
                        ></v-switch> -->
                            <v-card class="mx-auto justify-space-between" tile>
                              <v-btn elevation="2" @click="add_reaction"
                                >Add Reaction</v-btn
                              >

                              <v-list-item
                                v-for="(item, i) in editedItem.reactions"
                                :key="i"
                              >
                                <v-list-item-content>
                                  <v-list-item-title>
                                    <v-row>
                                      <v-col cols="2">
                                        <v-checkbox
                                          v-model="
                                            editedItem.reactions[i].momentary
                                          "
                                          label="Momentary"
                                        ></v-checkbox>
                                      </v-col>
                                      <v-col cols="4">
                                        <v-text-field
                                          label="Reaction ID"
                                          v-model="editedItem.reactions[i].id"
                                        ></v-text-field>
                                      </v-col>
                                      <v-col cols="4">
                                        <v-text-field
                                          label="Reaction Icon URL"
                                          v-model="
                                            editedItem.reactions[i].icon_url
                                          "
                                        ></v-text-field>
                                      </v-col>
                                      <v-col cols="1">
                                        <v-btn
                                          elevation="2"
                                          @click="remove_reaction(i)"
                                          >X</v-btn
                                        >
                                      </v-col>
                                    </v-row>
                                  </v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                            </v-card>
                          </v-col>

                          <v-col cols="6" sm="12" md="6">
                            <v-text-field
                              v-model="editedItem.database_url"
                              label="Database URL"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-tab-item>

                      <v-tab-item>
                        <reactionSystemEditor
                          :location="editingLocation"
                          :editedItem="editedItem"
                          @reaction-clip-saved="reaction_clip_saved"
                        ></reactionSystemEditor>
                      </v-tab-item>
                    </v-tabs-items>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="close"
                    >Cancel</v-btn
                  >
                  <v-btn color="blue darken-1" text @click="save">Save</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
          <!-- <v-icon small @click="deleteItem(item)">mdi-delete</v-icon> -->
        </template>
        <template v-slot:no-data>
          <v-btn color="primary" @click="fetch_locations">Refresh</v-btn>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
// import userDisplay from "@/components/user_display"
// import user_component from "@/components/user_component"
import * as validation from "@/modules/form_validation_functions";
import RoomSelector from "@/components/room_selector";
import reaction_system_editor from "@/components/reaction_system_editor";
import firebaseConfig from "../../../firebase";
import rtdb from "../../../firebase";
import firebase from "firebase/app";
import bus from "@/event_bus.js";
import groupBy from "lodash";
import moment from "moment";
import "moment-timezone";
const fbFunctions = firebase.functions();
export default {
  name: "reactionSystem",
  components: { RoomSelector, reactionSystemEditor: reaction_system_editor },
  data: () => ({
    page: {
      title: "Reaction System"
    },
    tab: null,
    breadcrumbs: [
      {
        text: "Games",
        disabled: true
      },
      {
        text: "Reactions",
        disabled: true
      }
    ],
    rules: validation.rules,

    dialog: false,
    headers: [
      { text: "URL", value: "pretty_url" },
      { text: "Display Name", value: "display_name" },
      { text: "Actions", value: "actions", sortable: false }
    ],
    editedIndex: -1,
    editedItem: {},
    editingLocation: null,
    defaultItem: {
      enabled: false,
      database_url: "",
      reactions: []
    },
    search: "",
    sort_key: "last_name",
    selected_index: -1,
    selected_user_for_edit: null,
    last_seen: null,
    FBPathname: "reactions",
    reactions_app: null,
    reactions_db: null,
    roomRef: null,
    roomConfigRef: null,
    room_data: null,
    open_editor: false,
    max_widths: ["100%", "100%"]
  }),
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Location" : "Edit Location";
    },
    allLocations() {
      return this.$store.state.locations;
    },

    activeLocations() {
      let active = [];
      const locations = this.$store.state.locations;
      for (const location of locations) {
        if (
          this.FBPathname in location &&
          location[this.FBPathname]["enabled"] === true
        ) {
          active.push(location);
        }
      }
      return active;
    },

    location_list_key() {
      return this.$store.state.location_list_key;
    }
  },
  watch: {
    dialog(val) {
      val || this.close();
    }
  },
  filters: {},
  created() {
    this.initialize();
    this.fetch_locations();
  },

  methods: {
    initialize() {},

    add_reaction() {
      this.editedItem.reactions.push({ id: "", icon_url: "" });
    },
    remove_reaction(index) {
      this.editedItem.reactions.splice(index, 1);
    },
    async editItem(item) {
      this.editedIndex = this.allLocations.indexOf(item);
      console.log("edited index: ", this.editedIndex);
      this.editingLocation = {
        url: item.url,
        display_name: item.display_name
      };
      this.editedItem = Object.assign({}, this.defaultItem, item.reactions);

      const setup_app = await this.setup_app();
      const setup_room = await this.create_room();
      this.dialog = true;
    },

    deleteItem(item) {
      const index = this.desserts.indexOf(item);
      confirm("Are you sure you want to delete this item?") &&
        this.desserts.splice(index, 1);
    },

    close() {
      this.dialog = false;
      try {
        this.delete_app();
      } catch (error) {
        console.log("already deleted app");
      }
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },

    async save() {
      if (this.tab === 0) {
        if (this.editedIndex > -1) {
          console.log("saving an edited location");
          this.$store.dispatch("update_location", [
            {
              id: this.allLocations[this.editedIndex].url,
              payload: this.editedItem,
              target: "reactions"
            }
          ]);
          const room_created = await this.create_room();
          let self = this;
          if (room_created === true) {
            let curStatusOfReactionRoom = this.room_data;
            let reactionMap = {};
            let reactionConfigMap = {};
            this.editedItem.reactions.forEach((item, index) => {
              let newReactionID = item.id;
              if (item.id in curStatusOfReactionRoom) {
                reactionMap[newReactionID] =
                  curStatusOfReactionRoom[newReactionID];
              } else {
                reactionMap[newReactionID] = 0;
              }
              reactionConfigMap[newReactionID] = item;
            });
            this.roomRef.set(reactionMap);
            this.roomConfigRef.update({ reactions: reactionConfigMap });
          }
        } else {
          console.log("saving a new location");

          this.$store.dispatch("update_location", {
            id: this.allLocations[this.editedIndex].url,
            payload: this.editedItem,
            target: "reactions"
          });
        }
        this.close();
      } else if (this.tab === 1) {
        // saving a reaction clip
        bus.$emit("reaction-clip-save-request");
      }
    },
    async reaction_clip_saved(data) {
      console.log("Reaction CLip: ", data);
      /**
     *    "start" : 1613930294049,
          "end" : 1613930395637,
          "location" : "main-stage%2F1062",
          "name" : "Tim Clip",
          "vimeo_id" : "myURL"
     */

      // console.log("secondary database: ", data);
      // console.log("Reaction Clip Incoming Data: ", data);
      let saved_data_ref = this.reactions_db.ref("/reactions/saved_data");

      let locationRef = saved_data_ref.child(data.location);
      let start_time = moment.tz(data.start, "America/New_York").valueOf();
      let end_time = moment.tz(data.end, "America/New_York").valueOf();
      let diff_time = end_time - start_time;

      let resolution;
      console.log("Dif time: ", diff_time);
      // let configuration = data.config;

      if (data.resolution) {
        resolution = data.resolution;
      } else {
        resolution = 60;
      }

      // console.log(time_labels);
      // console.log(data);
      let vimeo_id = data.vimeo_id;
      let curRTDB = rtdb;
      let clip_name = data.name;
      let saved_clips_ref = firebase
        .database()
        .ref("/reactions/saved_clips/" + vimeo_id);
      const res_data = await locationRef
        .orderByChild("timestamp")
        .startAt(start_time)
        .endAt(end_time)
        .once("value");

      // console.log("Res data: ", Object.values(res_data.val()));
      let testItem = {};
      const final_data = res_data.val();
      console.log(res_data.val());
      const groupedByReaction = groupBy(Object.values(final_data), reaction => {
        return reaction.reaction;
      });

      let segment_duration = diff_time / resolution;
      let time_slots = [];
      let time_labels = [];
      for (var i = 0; i < resolution; i++) {
        let timeItem = Math.floor(start_time + segment_duration * i);
        let default_all_reaction_obj = [];
        Object.keys(groupedByReaction).forEach(item => {
          groupedByReaction[item].push({
            timestamp: timeItem,
            reaction: item
          });
        });
        console.log("default obj: ", default_all_reaction_obj);
        time_slots.push(default_all_reaction_obj);
        time_labels.push(timeItem.toString());
        console.log(JSON.stringify(Object.values(time_slots[i])) + "\n");
      }
      console.log("Grouped by reaction length");
      for (var reaction in groupedByReaction) {
        console.log(groupedByReaction[reaction].length);
      }
      /**
       * returns a time rounded down to the nearest minute
       * @param {*} reaction
       */
      let get_minute = reaction => {
        let curDif = reaction.timestamp - start_time;
        let segment_number = Math.floor(curDif / segment_duration);
        return time_labels[segment_number];
      };

      let final_data_obj = {};
      Object.entries(groupedByReaction).forEach(item => {
        const groupedByTime = groupBy(item[1], get_minute, {});
        // console.log("grouped: ", groupedByTime);
        let reactionCounts = [];
        reactionCounts.push({
          x: start_time.toString(),
          y: 0
        });
        let groupedByTimeCount = Object.keys(groupedByTime).length;
        console.log("this time slot: ", Object.keys(groupedByTime).length);
        for (var curGroupTime in groupedByTime) {
          let timeAsInt = curGroupTime;
          reactionCounts.push({
            x: timeAsInt,
            y: groupedByTime[curGroupTime].length
          });
        }
        reactionCounts.push({
          x: end_time.toString(),
          y: 0
        });
        final_data_obj[item[0]] = reactionCounts;
      });

      const saved_clip_data = {
        data: final_data_obj,
        title: clip_name,
        vimeo_id: vimeo_id,
        location: data.location,
        start_time: start_time,
        end_time: end_time,
        labels: time_labels,
        config: data.config,
        display_timezone: data.display_timezone
      };
      console.log("Reaction Clip Final: ", saved_clip_data);
      await saved_clips_ref.set(saved_clip_data);
      return saved_clip_data;
    },

    async setup_app() {
      try {
        if (this.editedItem.database_url !== "") {
          let newFirebaseConfig = Object.assign({}, firebaseConfig);
          newFirebaseConfig.databaseURL = this.editedItem.database_url;
          this.reactions_app = firebase.initializeApp(
            newFirebaseConfig,
            "reactions_app"
          );
          this.reactions_db = this.reactions_app.database();
          return true;
        }
      } catch (error) {
        console.error(error);
        return false;
      }
    },
    async create_room() {
      let self = this;
      if (this.reactions_db !== null) {
        let room_url = this.allLocations[this.editedIndex].url;
        this.roomRef = this.reactions_db.ref(`reactions/counters/${room_url}`);
        this.roomConfigRef = this.reactions_db.ref(
          `reactions/config/${room_url}`
        );
        this.roomRef.once("value").then(snapshot => {
          let val = snapshot.val();
          if (val === null) {
            self.roomRef.set({ "momentary_fa-thumbs-up": 0 });
          } else {
            self.room_data = val;
          }

          console.log("In create room");
        });
        return true;
      } else {
        return false;
      }
    },
    async delete_app() {
      // todo --- fix this.. the app isn't being deleted properly
      try {
        if (this.reactions_app) {
          await this.reactions_app.delete();
          this.reactions_app = null;
          this.reactions_db = null;
        }
      } catch (error) {
        console.error("Could not delete app");
      }
    },
    // create_clip() {

    // console.log(fbFunctions.httpsCallable);
    // const fbFunction = fbFunctions.httpsCallable("createReactionClip");
    // const data = {
    //   start: 1613930295637,
    //   end: 1613970937097,
    //   location: "main-stage%2F1062",
    //   name: "Tim Clip",
    //   vimeo_id: "my_vimeo_url"
    // };
    // const res = await fbFunction(data);
    // console.log(res);
    // },

    fetch_locations() {
      console.log(this.sort_key);
      this.$store.dispatch("get_locations");
    },
    saveData() {
      let room = this.editingLocation.url;
      let pathToSavedData = this.reactions_db.ref(
        `reactions/saved_data/${room}`
      );
      let pathToReactions = this.reactions_db.ref(`reactions/counters/${room}`);
      pathToSavedData.once("value").then(snapshot => {
        console.log("room data", snapshot.val());
        let label = `reactions_data_${room}.json`;
        let json_data = JSON.stringify(snapshot.val());
        const blob = new Blob([json_data], { type: "application/json" });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = label;
        link.click();
        URL.revokeObjectURL(link.href);
      });
      pathToReactions.once("value").then(snapshot => {
        console.log("reactions data", snapshot.val());
        let label = `reactions_totals_${room}.json`;

        let json_data = JSON.stringify(snapshot.val());
        const blob = new Blob([json_data], { type: "application/json" });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = label;
        link.click();
        URL.revokeObjectURL(link.href);
      });
    }
  }
};
</script>

<style></style>
