<template>
  <v-row
    ><v-col>
      <v-list-item-subtitle class="text-wrap"></v-list-item-subtitle>
      <div class="mt-4">
        <v-divider></v-divider>
        <AdminLayoutTable
          ref="admin-layout-table"
          table_title="Locations"
          :headers="headers"
          :items="allLocations"
          v-on:edit_item="editItem"
          v-on:delete_item="confirm_delete"
          v-on:selected_items="getSelection"
          item_key="url"
        >
          <template v-slot:importer>
            <csv-importer-view
              button-title="Create Locations"
              item-type="Location"
              v-on:save-import="saveImport"
              :import_headers="import_headers"
            >
              <template v-slot:singleItemImporter>
                <SingleCreator @single-item-saved="single_item_saved">
                  <template
                    v-slot:form-input="{
                      form,
                      validationFunctions,
                      urlErrors,
                      nameErrors
                    }"
                  >
                    <v-col cols="12" sm="12" md="12">
                      <v-text-field
                        v-model.trim="form.url"
                        label="URL"
                        required
                        :error-messages="urlErrors"
                      ></v-text-field>
                      <small>(required)</small>
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                      <v-text-field
                        v-model.trim="form.display_name"
                        :error-messages="nameErrors"
                        label="Display Name"
                        required
                      ></v-text-field>
                      <small>(required)</small>
                    </v-col>
                  </template>
                </SingleCreator>
              </template>
            </csv-importer-view>
          </template>
        </AdminLayoutTable>
        <generic-editor
          ref="generic_editor"
          :editedItem="editedItem"
          title="Edit Location"
          @edited_item="update_location"
        >
          <template v-slot:form_input="form_input">
            <div>
              <v-col cols="12">
                <v-text-field
                  v-model="form_input.internal_edit_item.url"
                  label="URL"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="form_input.internal_edit_item.display_name"
                  label="Display Name"
                ></v-text-field>
                <v-switch> </v-switch>
              </v-col>
            </div>
          </template>
        </generic-editor>
        <confirmation-dialog ref="confirm" />
      </div>
    </v-col>
    <v-col>
      <v-list-item-subtitle class="text-wrap"></v-list-item-subtitle>
      <div class="mt-4">
        <v-divider></v-divider>
        <v-data-table
          :headers="pointHeaders"
          :items="pointNames"
          class="elevation-1"
          item-key="name"
          show-select
          single-select
          v-model="selected"
          v-on:click="console.log(items)"
          value="currentSelection"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>Scheduler</v-toolbar-title>
              <v-spacer></v-spacer>
              <div class="my-2">
                <v-btn
                  x-large
                  :color="activePoint ? 'error' : 'success'"
                  :disabled="!selected.length > 0"
                  class="{to}"
                  @click="pointTrigger"
                >
                  {{ activePoint ? "Deactivate Point" : "Activate Point" }}
                </v-btn>
              </div>

              <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                  <v-card-title class="headline"
                    >Are you sure you want to delete this item?
                  </v-card-title>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDelete"
                      >Cancel
                    </v-btn>
                    <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                      >OK
                    </v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon small class="mr-2" @click="editItem(item)">
              mdi-pencil
            </v-icon>
            <v-icon small @click="deleteItem(item)">
              mdi-delete
            </v-icon>
          </template>
          <template v-slot:no-data>
            <v-btn color="primary" @click="initialize">
              Reset
            </v-btn>
          </template>
        </v-data-table>
      </div>
    </v-col>
  </v-row>
</template>

<script>
// eslint-disable-next-line no-unused-vars
// import userDisplay from "@/components/user_display"
// import user_component from "@/components/user_component"
import room_selector from "@/components/room_selector";
import AdminLayoutTable from "@/components/AdminLayoutTable";
import CSVImporterView from "@/components/csvData/CSVImporterView";
// import SingleUserCreator from "@/components/single_creator_forms/SingleUserCreator";
import SingleCreator from "@/components/single_creator_forms/SingleCreator";
import { rtdb } from "@/firebase";
import bus from "@/event_bus.js";
import generic_editor from "@/components/generic_editor";

export default {
  name: "Locations",
  components: {
    AdminLayoutTable,
    "csv-importer-view": CSVImporterView,
    SingleCreator,
    confirmationDialog: () => import("@/components/confirmationDialog"),
    "generic-editor": generic_editor
  },
  data: () => ({
    dialog: false,
    selected: [],
    headers: [
      { text: "URL", value: "pretty_url" },
      { text: "Display Name", value: "display_name" },
      { text: "Actions", value: "actions", sortable: false }
    ],
    pointHeaders: [
      { text: "Name", value: "name" },
      { text: "Actions", value: "actions", sortable: false }
    ],
    editedIndex: -1,
    editedItem: {
      url: "",
      display_name: ""
    },
    defaultItem: {
      url: "",
      display_name: ""
    },
    search: "",
    sort_key: "last_name",
    selected_index: -1,
    selected_user_for_edit: null,
    last_seen: null,
    selectedLocationProperties: [],
    dialogDelete: false,
    desserts: [],
    selectedItems: [],
    selectedLocation: null,
    pointNames: [],
    currentSelection: {},
    activePoint: false,
    import_headers: [
      {
        slug: "url",
        required: true,
        tooltip: `e.g. libraries/120292
          The webpage that will be configured with a game. Do not encode the url.
          `
      },
      {
        slug: "display_name",
        required: true,
        tooltip: `e.g. Central Chumps
          The pretty name for this url- only visible in the UI.
          `
      }
    ]
  }),
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Location" : "Edit Location";
    },
    allLocations() {
      // console.log(user_component)

      let locations = this.$store.state.locations;
      let rooms = [];
      locations.forEach(element => {
        rooms.push({ url: element.id });
      });
      return locations;
    },
    location_list_key() {
      return this.$store.state.location_list_key;
    }
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    selected(val) {
      let curVal;
      if (val.length > 0) {
        curVal = val[0].name;
      } else {
        curVal = "";
      }
      rtdb
        .ref("io_panel/scheduled_pages")
        .child(this.selectedLocation)
        .child("active_point")
        .set(curVal);
    }
  },
  created() {
    this.initialize();
    this.fetch_locations();
    this.$store.watch(
      state => {
        return this.$store.state.client_selection;
      },
      (newValue, oldValue) => {
        this.fetch_locations();
      },
      {
        deep: true
      }
    );
  },
  methods: {
    async getLocationItems() {
      let pointNames = [];

      let fbPoints;
      if (this.selectedLocation) {
        await rtdb
          .ref("io_panel/scheduled_pages")
          .child(this.selectedLocation)
          .once("value")
          .then(snapshot => {
            if (snapshot.exists()) {
              console.log(snapshot.val());
              fbPoints = snapshot.val().points;
              pointNames = fbPoints.map(point => ({
                name: point
              }));
              this.pointNames = pointNames;
            }
          });
      }
      return pointNames;
    },

    async single_item_saved(data) {
      console.log("item saved");
      const form_res = await this.$store.dispatch("create_location", [data]);
      if (form_res === true) {
        console.log(form_res);
        bus.$emit("form-submitted");
      }
    },
    async update_location(item) {
      let updateData = {
        index: this.editedIndex,
        data: item
      };
      this.$store.dispatch("basic_location_update", updateData);
      this.$nextTick(function() {
        this.$refs.generic_editor.close();
      });
    },

    deleteItem(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    pointTrigger(evt) {
      this.activePoint = !this.activePoint;
      console.log(evt);

      let msg;

      if (this.activePoint) {
        msg = "go";
      } else {
        msg = "";
      }

      rtdb
        .ref("io_panel/scheduled_pages")
        .child(this.selectedLocation)
        .child("point_trigger")
        .set(msg);
    },
    deleteItemConfirm() {
      this.desserts.splice(this.editedIndex, 1);
      this.closeDelete();
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    getSelection(itemsFromChild) {
      if (itemsFromChild.length === 1) {
        this.selectedLocation = itemsFromChild[0].url;
        this.getLocationItems();
      } else if (itemsFromChild.length === 0) {
        this.pointNames = [];
      }
    },
    saveImport(data) {
      // console.log("saving a new location");
      // console.log(data.url);
      // this.editedItem.url = this.editedItem.url.replace(/^\/|[.]/g, "");
      // this.editedItem.url = encodeURIComponent(this.editedItem.url);
      // console.log(this.editedItem.url);

      this.$store.dispatch("create_location", data);
      // this.$store.dispatch("get_locations");
    },
    editItem(item) {
      this.editedIndex = this.$store.state.locations.indexOf(item);
      let testIndex = this.editedIndex;
      this.editedItem = Object.assign({}, item);

      this.dialog = true;
      this.$nextTick(function() {
        this.$refs.generic_editor.show();
      });
    },
    delete_item(item, selection) {
      if (selection.includes(item) === false) {
        selection.push(item);
      }
      this.$store.dispatch("delete_location", selection);
      this.$refs["admin-layout-table"].clear_selection();
      // console.log(this.$refs);
    },
    close() {
      this.dialog = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },
    initialize() {},
    async save(editedItem) {
      if (this.editedIndex > -1) {
        console.log("saving an edited location");
        [this.editedItem];
        await this.$store.dispatch("update_location", "dsfad");
      } else {
        console.log("saving a new location");
        console.log(this.editedItem.url);
        this.editedItem.url = this.editedItem.url.replace(/^\/|[.]/g, "");
        // this.editedItem.url = encodeURIComponent(this.editedItem.url);
        console.log(this.editedItem.url);

        await this.$store.dispatch("create_location", [this.editedItem]);
        this.$store.dispatch("get_locations");
      }
      this.close();
    },
    fetch_locations() {
      this.$store.dispatch("get_locations");
    },
    async confirm_delete(item, selection) {
      if (
        await this.$refs.confirm.open(
          "Confirm",
          "Are you sure you want to delete this record?"
        )
      ) {
        this.delete_item(item, selection);
      }
    }
  }
};
</script>
<style></style>
