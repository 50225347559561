<template>
  <div>
    <BaseBreadcrumb
      :title="page.title"
      :icon="page.icon"
      :breadcrumbs="breadcrumbs"
    ></BaseBreadcrumb>
    <v-list-item-subtitle class="text-wrap"> </v-list-item-subtitle>
    <div class="mt-4">
      <v-card-title>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="activeLocations"
        sort-by="display_name"
        class="border"
        :search="search"
      >
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-toolbar-title></v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-dialog v-model="dialog" max-width="500px">
              <template v-slot:activator="{ on }">
                <room-selector
                  :location-list="allLocations"
                  :activeSelection="activeLocations"
                  :target="FBPathname"
                  v-on="on"
                >
                </room-selector>
              </template>
              <v-card>
                <v-card-title>
                  <span class="headline">{{ formTitle }}</span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-row>
                      <date-time-table
                        v-if="editedItem.time_based === true"
                        :incoming_timeslots="editedItem.timeslots"
                        :timezone="editedItem.timezone"
                        @timeslots_changed="edited_timeslots"
                      ></date-time-table>
                      <v-col cols="12" sm="12" md="12">
                        <v-switch
                          v-model="editedItem.enabled"
                          label="Game Enabled"
                        ></v-switch>
                      </v-col>
                      <v-col cols="12" sm="12" md="12">
                        <v-switch
                          v-model="editedItem.moderated"
                          label="Moderated"
                        ></v-switch>
                      </v-col>
                      <v-col cols="12" sm="12" md="12">
                        <v-switch
                          v-model="editedItem.time_based"
                          label="Time Based"
                        ></v-switch>
                      </v-col>
                      <v-col cols="6" sm="12" md="6">
                        <v-text-field
                          v-model="editedItem.increment_rate"
                          label="Button Click Increment Rate"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6" sm="12" md="6">
                        <v-switch
                          v-model="editedItem.moderated_attendee_selection"
                          label="Give Points To Select Users"
                        ></v-switch>
                      </v-col>
                      <v-col cols="6" sm="12" md="6">
                        <v-text-field
                          type="number"
                          v-model="editedItem.moderated_attendee_default_value"
                          label="Default Point Value"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6" sm="12" md="6">
                        <v-text-field
                          v-model="editedItem.timezone"
                          label="Room Timezone"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" md="12">
                        <v-switch
                          v-model="editedItem.agenda_click"
                          label="Agenda Click Based"
                        ></v-switch>
                      </v-col>
                      <v-col
                        cols="6"
                        sm="6"
                        md="6"
                        v-if="editedItem.agenda_click"
                      >
                        <v-text-field
                          v-model="editedItem.padding_before"
                          label="Padding Before ( sec ) "
                        ></v-text-field>
                      </v-col>
                      <v-col
                        cols="6"
                        sm="6"
                        md="6"
                        v-if="editedItem.agenda_click"
                      >
                        <v-text-field
                          v-model="editedItem.padding_after"
                          label="Padding After ( sec ) "
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="close"
                    >Cancel</v-btn
                  >
                  <v-btn color="blue darken-1" text @click="save">Save</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
          <!-- <v-icon small @click="deleteItem(item)">mdi-delete</v-icon> -->
        </template>
        <template v-slot:no-data>
          <v-btn color="primary" @click="fetch_locations">Refresh</v-btn>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
// import userDisplay from "@/components/user_display"
// import user_component from "@/components/user_component"
import * as validation from "@/modules/form_validation_functions";
import RoomSelector from "@/components/room_selector";
import dateTimeTable from "@/components/date_time_table";
export default {
  name: "Visittowinit",
  components: { RoomSelector, dateTimeTable },
  data: () => ({
    page: {
      title: "Visit to Winit"
    },
    breadcrumbs: [
      {
        text: "Games",
        disabled: true
      },
      {
        text: "Visit to Winit",
        disabled: true
      }
    ],
    rules: validation.rules,

    dialog: false,
    headers: [
      { text: "URL", value: "pretty_url" },
      { text: "Display Name", value: "display_name" },
      { text: "Actions", value: "actions", sortable: false }
    ],
    editedIndex: -1,
    editedItem: {},
    defaultItem: {
      enabled: false,
      increment_rate: 0.25,
      moderated: false,
      time_based: false,
      timezone: "America/New_York",
      timeslots: [],
      moderated_attendee_selection: false,
      moderated_attendee_default_value: 5,
      agenda_click: false,
      padding_before: 300,
      padding_after: 300
    },
    search: "",
    sort_key: "last_name",
    selected_index: -1,
    selected_user_for_edit: null,
    last_seen: null,
    FBPathname: "visit_to_winit"
  }),
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Location" : "Edit Location";
    },
    allLocations() {
      return this.$store.state.locations;
    },
    activeLocations() {
      let active = [];
      const locations = this.$store.state.locations;
      for (const location of locations) {
        if (
          this.FBPathname in location &&
          location[this.FBPathname]["enabled"] === true
        ) {
          active.push(location);
        }
      }
      return active;
    },

    location_list_key() {
      return this.$store.state.location_list_key;
    }
  },
  watch: {
    dialog(val) {
      val || this.close();
    }
  },
  filters: {},
  created() {
    this.initialize();
    this.fetch_locations();
  },

  methods: {
    initialize() {},
    edited_timeslots(data) {
      console.log("TIMESLOTS CHANGED: ", data);
      let new_timeslots = [];
      data.forEach(item => {
        let newItem = {
          start_time: item[0],
          end_time: item[1]
        };
        new_timeslots.push(newItem);
      });
      this.editedItem.timeslots = new_timeslots;
    },

    editItem(item) {
      this.editedIndex = this.allLocations.indexOf(item);
      console.log("edited index: ", this.editedIndex);
      this.editedItem = Object.assign(
        {},
        this.defaultItem,
        item.visit_to_winit
      );

      this.dialog = true;
    },

    deleteItem(item) {
      const index = this.desserts.indexOf(item);
      confirm("Are you sure you want to delete this item?") &&
        this.desserts.splice(index, 1);
    },

    close() {
      this.dialog = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },

    save() {
      if (this.editedIndex > -1) {
        console.log("saving an edited location");
        this.$store.dispatch("update_location", [
          {
            id: this.allLocations[this.editedIndex].url,
            payload: this.editedItem,
            target: "visit_to_winit"
          }
        ]);
      } else {
        console.log("saving a new location");

        this.$store.dispatch("update_location", {
          id: this.allLocations[this.editedIndex].url,
          payload: this.editedItem,
          target: "visit_to_winit"
        });
      }
      this.close();
    },

    fetch_locations() {
      console.log(this.sort_key);
      this.$store.dispatch("get_locations");
    }
  }
};
</script>

<style></style>
