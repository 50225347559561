<template>
  <form>
    <v-container>
      <v-row>
        <v-card>
          <v-card-title>
            <span class="headline">{{ formTitle }}</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row>
                <slot
                  name="form-input"
                  :form="form"
                  :urlErrors="urlErrors"
                  :nameErrors="nameErrors"
                ></slot>
              </v-row>
              <p>asdfasdf{{ required }}</p>
            </v-container>
          </v-card-text>
        </v-card>
      </v-row>

      <v-btn class="mr-4" @click="submit">
        submit
      </v-btn>
      <v-btn class="mr-4" @click="clear">
        clear
      </v-btn>
      <v-btn @click="cancel">
        cancel
      </v-btn>
    </v-container>
  </form>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, helpers } from "vuelidate/lib/validators";
import bus from "@/event_bus.js";
const urlStartsEndsWithSlash = value => {
  if (value === undefined) return true;
  if (value.slice(0, 1)[0] === "/" || value.slice(-1)[0] === "/") {
    return false;
  } else {
    return true;
  }
};

export default {
  mixins: [validationMixin],
  name: "SingleCreator",
  validations: {
    form: {
      url: { required, urlStartsEndsWithSlash },
      display_name: { required }
    }
  },

  data: () => ({
    form: {},
    formTitle: "Create Location",
    submitStatus: null
  }),

  computed: {
    urlErrors() {
      const errors = [];
      // let curURL = this.form.url;
      if (!this.$v.form.url.$dirty) return errors;

      if (!this.$v.form.url.urlStartsEndsWithSlash) {
        errors.push("Cannot have '/' in head or tail of url");
      }
      console.log("starts with /", this.$v.form.url.urlStartsEndsWithSlash);
      !this.$v.form.url.required && errors.push("Url is required.");
      console.log("what is required: ", this.$v.form.url.required);
      return errors;
    },
    required() {
      return this.$v.form.url.required;
    },
    nameErrors() {
      const errors = [];
      if (!this.$v.form.display_name.$dirty) return errors;
      !this.$v.form.display_name.required &&
        errors.push("Display Name is required.");
      return errors;
    },
    isValid() {
      return !this.$v.$invalid;
      // return true;
    },
    my_form: function() {
      return this.form.url;
    },
    validationFunctions: function() {
      return {
        urlErrors: this.urlErrors,
        nameErrors: this.nameErrors
      };
    }
  },

  methods: {
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.submitStatus = "ERROR";
      } else {
        this.submitStatus = "OK";
        this.$emit("single-item-saved", this.form);
      }
    },
    clear() {
      this.$v.$reset();
      this.form = Object.assign({}, {});
    },
    cancel() {
      bus.$emit("form-submitted");
      this.clear();
    }
  }
};
</script>

<style scoped></style>
