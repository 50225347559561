<template>
  <div>
    <v-row justify="center" v-if="mode >= 0">
      <v-col
        ><h1>Choose a {{ mode === 0 ? "Start" : "End" }} Time</h1></v-col
      >
      <v-col>
        <v-date-picker v-model="date_picker"></v-date-picker>
      </v-col>
      <v-col>
        <v-time-picker format="ampm" v-model="time_picker"></v-time-picker>
      </v-col>
    </v-row>
    <v-row>
      <v-simple-table>
        <template v-slot:default>
          <v-btn elevation="2" v-if="mode === -1" @click="create_new_timeslot"
            >Add Timeslot</v-btn
          >
          <v-btn
            elevation="2"
            v-else-if="mode >= 0 && mode < 2"
            @click="assign_time"
            >Select {{ mode === 0 ? "Start" : "End" }} Time</v-btn
          >
          <v-btn elevation="2" v-else-if="mode === 2" @click="save_timeslot"
            >Save</v-btn
          >
          <thead>
            <tr>
              <th class="text-left">
                Start Time
              </th>
              <th class="text-left">
                End Time
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, i) in timeslots" :key="item.start_time">
              <td>{{ time_format(item[0]) }}</td>
              <td>{{ time_format(item[1]) }}</td>
              <td>
                <v-btn elevation="2" @click="remove_timeslot(i)">X</v-btn>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-row>
  </div>
</template>

<script>
import moment from "moment";
// import "moment-timezone";
export default {
  name: "dateTimeTable",
  data() {
    return {
      edited_item_index: 0,
      mode: -1, // -1 for closed 0 for start, 1 for end
      date_picker: "",
      time_picker: "",
      timeslots: [],
      edited_item: ["", ""]
    };
  },
  computed: {
    computed_date_time() {
      return this.date_picker + " " + this.time_picker;
    }
  },
  props: {
    incoming_timeslots: {
      type: Array
    },
    timezone: {
      type: String
    }
  },
  watch: {
    // incoming_timeslots(newValue, oldValue) {
    //   this.timeslots = newValue;
    // }
  },
  methods: {
    time_format: function(val) {
      let self = this;
      return moment.tz(val, self.timezone).format("MM/DD/YY - hh:mm");
    },
    create_new_timeslot() {
      let newTimeslot = ["", ""];
      this.edited_item = newTimeslot;
      this.mode = 0;
    },
    assign_time() {
      this.edited_item[this.mode] = this.computed_date_time;
      this.mode += 1;
    },
    save_timeslot() {
      this.timeslots.splice(this.timeslots.length - 1, 0, this.edited_item);
      this.mode = -1;
      this.timeslots = this.timeslots.sort((a, b) => {
        return moment(a, "YYYY-MM-DD hh:mm") < moment(b, "YYYY-MM-DD hh:mm")
          ? -1
          : 1;
      });
      this.$emit("timeslots_changed", this.timeslots);
    },
    remove_timeslot(index) {
      this.timeslots.splice(index, 1);
      this.$emit("timeslots_changed", this.timeslots);
    }
  },
  created() {
    this.timeslots = [];
    this.incoming_timeslots.forEach(item => {
      let newTimeslot = [item.start_time, item.end_time];
      this.timeslots.push(newTimeslot);
    });
    this.timeslots.sort((a, b) => {
      return moment(a) < moment(b);
    });
  }
};
</script>
