<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" scrollable max-width="500px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" absolute dark v-bind="attrs" v-on="on" right>
          Room Manager
        </v-btn>
      </template>

      <v-card>
        <v-card-title>Select Active Page(s)</v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 300px;">
          <v-data-table
            v-model="selected"
            :headers="headers"
            :items="locationList"
            item-key="url"
            show-select
            class="elevation-1"
            :hide-default-footer="true"
            :items-per-page="-1"
          >
          </v-data-table>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <!--          <v-btn color="blue darken-1" text @click="dialog = false">-->
          <!--            Copy-->
          <!--          </v-btn>-->
          <!--          <v-btn color="blue darken-1" text @click="dialog = false">-->
          <!--            Paste-->
          <!--          </v-btn>-->
          <v-btn
            elevation="2"
            color="blue darken-1"
            text
            @click="dialog = false"
          >
            Cancel
          </v-btn>
          <v-btn
            elevation="2"
            absolute
            right
            color="blue darken-1"
            text
            @click="save"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: "roomSelector",
  props: {
    locationList: {
      type: Array,
      required: true
    },
    activeSelection: {
      type: Array
    },
    target: {
      type: String
    }
  },
  data() {
    return {
      dialogm1: "",
      dialog: false,
      singleSelect: false,
      rooms: null,
      selected: [],
      editedRooms: [],
      headers: [
        {
          text: "Website Page",
          align: "start",
          sortable: true,
          value: "display_name"
        }
      ]
    };
  },
  methods: {
    copySelection() {},
    save() {
      let enabledState;
      let readyForUpdate = [];
      for (let room of this.editedRooms) {
        readyForUpdate.push({
          id: room.url,
          payload: room.currentState,
          target: this.target,
          merge: true
        });
      }
      this.$store.dispatch("update_location", readyForUpdate);
      this.dialog = false;
    },
    symmetricDifference(setA, setB) {
      let _difference = new Set(setA);
      for (let elem of setB) {
        if (_difference.has(elem)) {
          _difference.delete(elem);
        } else {
          _difference.add(elem);
        }
      }
      return _difference;
    },
    logChanges(updatedItem) {
      updatedItem.forEach(item => {
        let isSelected = this.selected.includes(item);
        let isCurrentlyActive = this.activeSelection.includes(item);
        // activating a new room | true / false
        // is selected and not current an active room
        if (isSelected && !isCurrentlyActive) {
          item["currentState"] = { enabled: true };
          this.editedRooms.push(item);
          //  de-activating a currently activated room | false / true
        } else if (!isSelected && isCurrentlyActive) {
          item["currentState"] = { enabled: false };
          this.editedRooms.push(item);
        } else if (
          // activating a currently active room | true/true | false/false
          // de-activating a non-active room
          (isSelected && isCurrentlyActive) ||
          (!isSelected && !isCurrentlyActive)
        ) {
          this.editedRooms.splice(this.editedRooms.indexOf(item), 1);
        }
      });
    }
  },
  watch: {
    dialog: function(curState, prevState) {
      if (curState) {
        this.editedRooms.length = 0;
        this.selected.length = 0;
        this.selected.push(...this.activeSelection);
      }
    },
    selected(curState, prevState) {
      let curSet = new Set([...curState]);
      let prevSet = new Set([...prevState]);
      let changedItems = Array(...this.symmetricDifference(curSet, prevSet));
      if (changedItems.length > 0) {
        this.logChanges(changedItems);
      }
    }
  }
};
</script>

<style scoped></style>
