<template>
  <v-card width="400" class="mx-auto mt-5">
    <v-card-title class="blue white--text">
      <h1 class="display-1">NMR Games Portal</h1>
      <v-spacer></v-spacer>
      <v-menu bottom left origin="top right" transition="scale-transition">
        <template v-slot:activator="{ on, attrs }">
          <v-btn dark icon v-bind="attrs" v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item v-for="(item, i) in menuItems" :key="i">
            <v-list-item-title>{{ item.menuTitle }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-card-title>

    <v-card-text>
      <v-form>
        <v-text-field
          v-model="loginForm.email"
          label="Email"
          prepend-icon="mdi-email"
        />
        <v-text-field
          v-model="loginForm.password"
          label="Password"
          :type="showPassword ? 'text' : 'password'"
          prepend-icon="mdi-lock"
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="showPassword = !showPassword"
          @keypress.enter="vLogin"
        />
      </v-form>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-btn color="success">
        Register
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn color="info" @click="vLogin">Login</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import PasswordReset from "@/components/PasswordReset";

export default {
  components: {
    /* eslint-disable-next-line */
    PasswordReset
  },
  data() {
    return {
      loginForm: {
        email: "",
        password: ""
      },
      site_options: {
        CGCACI: "aci",
        Twillio: "twillio"
      },
      selected_site: "",

      showLoginForm: true,
      showPasswordReset: false,

      show_invalid_password: false,
      showPassword: false,
      menuItems: [{ menuTitle: "CGACI" }, { menuTitle: "Twilio" }]
    };
  },
  methods: {
    toggleForm() {
      this.showLoginForm = !this.showLoginForm;
    },
    togglePasswordReset() {
      this.showPasswordReset = !this.showPasswordReset;
    },
    async login() {
      this.show_invalid_password = !(await this.$store.dispatch("login", {
        email: this.loginForm.email,
        password: this.loginForm.password
      }));
      if (this.show_invalid_password === true) {
        this.loginForm.password = "";
      }
    },
    async vLogin() {
      this.show_invalid_password = !(await this.$store.dispatch("login", {
        email: this.loginForm.email,
        password: this.loginForm.password
      }));
      if (this.show_invalid_password === true) {
        this.loginForm.password = "";
      }
    },
    signup() {
      this.$store.dispatch("signup", {
        email: this.signupForm.email,
        password: this.signupForm.password,
        name: this.signupForm.name,
        title: this.signupForm.title
      });
    }
  }
};
</script>

<style scoped>
#invalid_password * {
  color: red;
}
</style>
