<template>
  <div>
    <BaseBreadcrumb
      :title="page.title"
      :icon="page.icon"
      :breadcrumbs="breadcrumbs"
    ></BaseBreadcrumb>
    <v-list-item-subtitle class="text-wrap"> </v-list-item-subtitle>
    <div class="mt-4">
      <v-card-title>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="activeLocations"
        sort-by="display_name"
        class="border"
        :search="search"
      >
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-toolbar-title></v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-dialog v-model="dialog" max-width="500px">
              <template v-slot:activator="{ on }">
                <room-selector
                  :location-list="allLocations"
                  :activeSelection="activeLocations"
                  :target="FBPathname"
                  v-on="on"
                >
                </room-selector>
              </template>
              <v-card>
                <v-card-title>
                  <span class="headline">{{ formTitle }}</span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="12" md="12">
                        <v-switch
                          v-model="editedItem.enabled"
                          label="Game Enabled"
                        ></v-switch>
                      </v-col>

                      <v-col cols="6" sm="12" md="6">
                        <v-text-field
                          v-model="editedItem.attendee_directory_id"
                          label="Attendee Directory ID"
                          type="number"
                          :rules="[rules.number]"
                          required
                        ></v-text-field>
                      </v-col>

                      <v-col cols="6" sm="12" md="6">
                        <v-text-field
                          v-model="editedItem.default_title"
                          label="Default Title"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6" sm="12" md="6">
                        <v-switch
                          v-model="editedItem.enable_tutorial"
                          label="Enable Tutorial"
                          required
                        ></v-switch>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="close"
                    >Cancel</v-btn
                  >
                  <v-btn color="blue darken-1" text @click="save">Save</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
          <!-- <v-icon small @click="deleteItem(item)">mdi-delete</v-icon> -->
        </template>
        <template v-slot:no-data>
          <v-btn color="primary" @click="fetch_locations">Refresh</v-btn>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
// import userDisplay from "@/components/user_display"
// import user_component from "@/components/user_component"
import * as validation from "@/modules/form_validation_functions";
import RoomSelector from "@/components/room_selector";

export default {
  name: "Cocktaildirectory",
  components: { RoomSelector },
  data: () => ({
    page: {
      title: "Cocktail Directory"
    },
    breadcrumbs: [
      {
        text: "Games",
        disabled: true
      },
      {
        text: "Cocktail Directory",
        disabled: true
      }
    ],
    rules: validation.rules,
    dialog: false,
    headers: [
      { text: "URL", value: "pretty_url" },
      { text: "Display Name", value: "display_name" },
      { text: "Actions", value: "actions", sortable: false }
    ],
    editedIndex: -1,
    editedItem: {},
    defaultItem: {
      enabled: false,
      attendee_directory_id: -1,
      default_title: "Untitled Cocktail",
      enable_tutorial: true,
      image_options: {
        image1: { url: "" }
      },
      items_per_page: 10,
      vote_count: 3
    },
    search: "",
    sort_key: "last_name",
    selected_index: -1,
    selected_user_for_edit: null,
    last_seen: null,
    FBPathname: "cocktail_directory"
  }),
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Location" : "Edit Location";
    },
    activeLocations() {
      let active = [];
      const locations = this.$store.state.locations;
      for (const location of locations) {
        if (
          this.FBPathname in location &&
          location[this.FBPathname]["enabled"] === true
        ) {
          active.push(location);
        }
      }
      return active;
    },
    allLocations() {
      return this.$store.state.locations;
    },

    location_list_key() {
      return this.$store.state.location_list_key;
    }
  },
  watch: {
    dialog(val) {
      val || this.close();
    }
  },
  filters: {},
  created() {
    this.initialize();
    this.fetch_locations();
  },

  methods: {
    initialize() {},

    editItem(item) {
      this.editedIndex = this.allLocations.indexOf(item);
      console.log("edited index: ", this.editedIndex);
      this.editedItem = Object.assign(
        {},
        this.defaultItem,
        item.visit_to_winit
      );

      this.dialog = true;
    },

    deleteItem(item) {
      const index = this.desserts.indexOf(item);
      confirm("Are you sure you want to delete this item?") &&
        this.desserts.splice(index, 1);
    },

    close() {
      this.dialog = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },

    save() {
      if (this.editedIndex > -1) {
        console.log("saving an edited location");
        this.$store.dispatch("update_location", [
          {
            id: this.allLocations[this.editedIndex].url,
            payload: this.editedItem,
            target: "cocktail_directory"
          }
        ]);
      } else {
        console.log("saving a new location");

        this.$store.dispatch("update_location", {
          id: this.allLocations[this.editedIndex].url,
          payload: this.editedItem,
          target: "cocktail_directory"
        });
      }
      this.close();
    },

    fetch_locations() {
      console.log(this.sort_key);
      this.$store.dispatch("get_locations");
    }
  }
};
</script>

<style></style>
