<template>
  <v-dialog v-model="dialog" max-width="500px">
    <template v-slot:activator="{ on }">
      <v-btn color="primary" dark class="mb-2" v-on="on">New Group</v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="headline">{{ formTitle }}</span>
      </v-card-title>

      <v-card-text>
        <!--                  <v-container>-->
        <!--                    <div class="mt-4">-->
        <!--                      <v-tabs-->
        <!--                        v-model="tab"-->
        <!--                        fixed-tabs-->
        <!--                        background-color="primary"-->
        <!--                        dark-->
        <!--                      >-->
        <!--                        <v-tab> Group Data </v-tab>-->
        <!--                        <v-tab> Points </v-tab>-->
        <!--                      </v-tabs>-->
        <!--                    </div>-->

        <!--                    <v-tabs-items v-model="tab">-->
        <!--                      <v-tab-item>-->
        <!--                        <v-row>-->
        <!--                          <v-col cols="12" sm="6" md="6">-->
        <!--                            <v-text-field-->
        <!--                              v-model="editedItem.first_name"-->
        <!--                              label="First Name"-->
        <!--                            ></v-text-field>-->
        <!--                          </v-col>-->
        <!--                          <v-col cols="12" sm="6" md="6">-->
        <!--                            <v-text-field-->
        <!--                              v-model="editedItem.last_name"-->
        <!--                              label="Last Name"-->
        <!--                            ></v-text-field>-->
        <!--                          </v-col>-->
        <!--                          <v-col cols="12" sm="12" md="12">-->
        <!--                            <v-text-field-->
        <!--                              v-model="editedItem.email"-->
        <!--                              label="Email"-->
        <!--                            ></v-text-field>-->
        <!--                          </v-col>-->
        <!--                          <v-col cols="12" sm="12" md="12">-->
        <!--                            <v-text-field-->
        <!--                              v-model="editedItem.event_attendee_id"-->
        <!--                              label="Attendee ID"-->
        <!--                            ></v-text-field>-->
        <!--                          </v-col>-->
        <!--                          <v-col cols="12" sm="12" md="12">-->
        <!--                            <v-switch-->
        <!--                              v-model="editedItem.additionaldata.point_manager"-->
        <!--                              label="Point Manager"-->
        <!--                              v-if="editedItem.additionaldata"-->
        <!--                            ></v-switch>-->
        <!--                          </v-col>-->
        <!--                          <v-col cols="12" sm="12" md="12">-->
        <!--                            <v-switch-->
        <!--                              v-model="editedItem.additionaldata.on_leaderboard"-->
        <!--                              label="Shows Up On Leaderboard"-->
        <!--                              v-if="editedItem.additionaldata"-->
        <!--                            ></v-switch>-->
        <!--                          </v-col>-->
        <!--                          <v-col cols="12" sm="12" md="12">-->
        <!--                            <v-text-field-->
        <!--                              v-model="editedItem.additionaldata.region"-->
        <!--                              label="Region"-->
        <!--                              v-if="editedItem.additionaldata"-->
        <!--                            ></v-text-field>-->
        <!--                          </v-col>-->
        <!--                        </v-row>-->
        <!--                      </v-tab-item>-->

        <!--                      <v-tab-item>-->
        <!--                        <v-row>-->
        <!--                          <v-data-table-->
        <!--                            :headers="point_headers"-->
        <!--                            :items="point_array"-->
        <!--                            sort-by="display_name"-->
        <!--                            class="border"-->
        <!--                            :search="search"-->
        <!--                          ></v-data-table>-->
        <!--                        </v-row>-->
        <!--                      </v-tab-item>-->
        <!--                    </v-tabs-items>-->
        <!--                  </v-container>-->
      </v-card-text>
      <v-card-text> </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
        <v-btn color="blue darken-1" text @click="save">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "GroupEditorVT"
};
</script>

<style scoped></style>
