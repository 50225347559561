<template>
  <div>
    <v-list-item-subtitle class="text-wrap"></v-list-item-subtitle>
    <div class="mt-4">
      <v-divider></v-divider>
      <v-row>
        <v-col>
          <v-text-field
            v-model="email_search"
            append-icon="mdi-magnify"
            label="Search For UID from Email"
            single-line
            hide-details
          ></v-text-field>
          <v-btn color="primary" dark class="mb-2" @click="search_user_by_email"
            >Search</v-btn
          >
        </v-col>
        <v-col>
          <v-text-field
            v-model="last_name_search"
            append-icon="mdi-magnify"
            label="Search For UID from Last Name"
            single-line
            hide-details
          ></v-text-field>
          <v-btn
            color="primary"
            dark
            class="mb-2"
            @click="search_user_by_last_name"
            >Search</v-btn
          >
        </v-col>
      </v-row>
      <AdminLayoutTable
        table_title="Users"
        :headers="headerProps"
        :items="items"
        v-on:edit_item="edit_item"
        v-on:delete_item="confirm_delete"
        item_key="user_data.uid"
      >
        <template v-slot:importer>
          <csv-importer-view
            button-title="Create Users"
            item-type="User"
            v-on:save-import="saveImport"
            :import_headers="import_headers"
          >
            <template v-slot:singleItemImporter>
              <SingleUserCreator></SingleUserCreator>
            </template>
          </csv-importer-view>
        </template>
      </AdminLayoutTable>
      <generic-editor
        ref="generic_editor"
        :editedItem="selected_user_for_edit"
        title="Edit User"
        @edited_item="update_user"
      >
        <template v-slot:form_input="form_input">
          <div v-if="form_input.internal_edit_item.user_data">
            <v-col cols="6" sm="6" md="6">
              <v-text-field
                v-model="form_input.internal_edit_item.user_data.first_name"
                label="First Name"
              ></v-text-field>
            </v-col>
            <v-col cols="6" sm="6" md="6">
              <v-text-field
                v-model="form_input.internal_edit_item.user_data.last_name"
                label="Last Name"
              ></v-text-field>
              <v-switch> </v-switch>
            </v-col>
            <v-col
              cols="6"
              sm="6"
              md="6"
              v-for="item in Object.keys(
                form_input.internal_edit_item.user_data.additionaldata
              )"
              :key="item"
            >
              <component
                v-if="form_input.internal_edit_item.user_data"
                :is="
                  get_field_type(
                    form_input.internal_edit_item.user_data.additionaldata[item]
                  )
                "
                v-model="
                  form_input.internal_edit_item.user_data.additionaldata[item]
                "
                :label="item"
              ></component>
            </v-col>
          </div>
        </template>
      </generic-editor>

      <confirmation-dialog ref="confirm" />
    </div>
  </div>
</template>

<script>
import AdminLayoutTable from "@/components/AdminLayoutTable";
import user_editor from "@/components/user_editor";
import CSVImporterView from "@/components/csvData/CSVImporterView";
import point_creator_vt from "@/components/point_creator_vt";
import user_creator from "@/components/user_creator";
import SingleUserCreator from "@/components/SingleUserCreator";
import generic_editor from "@/components/generic_editor";
export default {
  //headers are supplied as a list of objects
  //Text is the display name/text is mapped to the item(s) key { text: "name", value: "name" },

  name: "AdminUserLayout",
  components: {
    AdminLayoutTable,
    "csv-importer-view": CSVImporterView,
    "generic-editor": generic_editor,

    SingleUserCreator,
    // point_creator_vt,
    // user_creator
    confirmationDialog: () => import("@/components/confirmationDialog")
  },
  props: {},
  data() {
    return {
      selected: [],
      dialog: false,
      formTitle: "My form title",
      tab: "tab data",
      email_search: "",
      last_name_search: "",
      headerProps: [
        { text: "First Name", value: "user_data.first_name" },
        { text: "Last Name", value: "user_data.last_name" },
        { text: "Username", value: "user_data.username" },
        { text: "Additional Data", value: "user_data.additionaldata" },
        { text: "Email", value: "user_data.email" },
        { text: "Attendee ID", value: "user_data.event_attendee_id" },
        { text: "EF Email", value: "user_data.event_email" },
        { text: "UID", value: "user_data.uid" },
        { text: "Actions", value: "actions", sortable: false }
      ],
      search: "",
      editedItem: {},
      items_per_page: 20,
      sort_key: "last_name",
      selected_index: -1,
      selected_user_for_edit: null,
      last_seen: null,
      import_headers: [
        {
          slug: "event_attendee_id",
          required: true,
          tooltip: `e.g. 12748127
          The Eventfinity Attendee ID for this user. This requires valid EF attendees.
          `
        },
        {
          slug: "event_email",
          required: true
        },

        {
          slug: "first_name",
          required: true
        },
        {
          slug: "last_name",
          required: true
        }
      ]
    };
  },
  methods: {
    fetch_users() {
      // console.log(this.sort_key);
      this.$store.dispatch("fetchUsers", {
        orderBy: "user_data." + this.sort_key,
        items_per_page: this.items_per_page
      });
    },
    get_field_type(field) {
      let fieldTypes = {
        string: "v-text-field",
        boolean: "v-switch"
      };

      try {
        let new_field = fieldTypes[typeof field];
        return new_field;
      } catch (error) {
        return null;
      }
    },
    close() {},
    update_user(data) {
      let availableOptions = {
        first_name: data.user_data.first_name,
        last_name: data.user_data.last_name,
        additional_data: data.user_data.additionaldata,
        event_attendee_id: data.user_data.event_attendee_id
      };

      this.$store.dispatch("update_user", [availableOptions]);
      this.$nextTick(function() {
        this.$refs.generic_editor.close();
      });
      console.log("Running from parent");
    },
    save() {},
    process_data() {},
    process_import() {},
    initialize() {},
    edit_item(userObj) {
      console.log("running edited item", userObj);
      this.selected_user_for_edit = userObj;
      this.dialog = true;
      this.$nextTick(function() {
        this.$refs.generic_editor.show();
      });
    },
    saveUser() {},
    delete_item(user, selection) {
      let users = [];
      if (selection.length > 0) {
        users = selection.map(selectedUsers => selectedUsers.user_data.uid);
      } else {
        users = user.user_data.uid;
      }

      console.log(users);
      this.$store.dispatch("delete_user", users);
    },
    saveBatchUsers() {},
    saveImport(csvData) {
      console.log(csvData);
      console.log(`running save import from ${this.name}`);
      this.$store.dispatch("batch_create_users", csvData);
    },
    search_user_by_email() {
      this.$store.dispatch("get_user_by_email", this.email_search);
    },
    search_user_by_last_name() {
      this.$store.dispatch("get_user_by_last_name", this.last_name_search);
    },
    async confirm_delete(item, selection) {
      if (
        await this.$refs.confirm.open(
          "Confirm",
          "Are you sure you want to delete this record?"
        )
      ) {
        this.delete_item(item, selection);
      }
    }
  },
  computed: {
    allItems() {
      return this.$store.state.user_list;
    },
    items() {
      return this.$store.state.user_list;
    }
  },
  created() {
    this.$store.watch(
      state => {
        return this.$store.state.client_selection;
      },
      (newValue, oldValue) => {
        this.fetch_users();
      },
      {
        deep: true
      }
    );
    this.fetch_users();
  }
};
</script>
<style scoped></style>
