function isNumeric(str) {
  if (typeof str === "number") return true;
  return (
    !isNaN(str) && !isNaN(parseFloat(str)) // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
  ); // ...and ensure strings of whitespace fail
}

const rules = {
  required: value => {
    console.log("val: ", isNumeric(value) === false);
    return !!value || "Required.";
  },
  number: value => {
    return isNumeric(value) || "Invalid Number.";
  }
};

export { rules };
