<template>
  <div>
    <BaseBreadcrumb
      :title="page.title"
      :icon="page.icon"
      :breadcrumbs="breadcrumbs"
    ></BaseBreadcrumb>
    <v-list-item-subtitle class="text-wrap"> </v-list-item-subtitle>
    <div class="mt-4">
      <v-card-title>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="activeLocations"
        sort-by="display_name"
        class="border"
        :search="search"
      >
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-toolbar-title> </v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-dialog v-model="dialog" max-width="500px">
              <template v-slot:activator="{ on }">
                <room-selector
                  :location-list="allLocations"
                  :activeSelection="activeLocations"
                  :target="FBPathname"
                  v-on="on"
                >
                </room-selector>
              </template>
              <v-card>
                <v-card-title>
                  <span class="headline">{{ formTitle }}</span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="6" sm="12" md="6">
                        <v-switch
                          v-model="editedItem.can_create_posts"
                          label="Can Create Posts"
                        ></v-switch>
                      </v-col>
                      <v-col cols="6" sm="12" md="6">
                        <v-switch
                          v-model="editedItem.editable_posts"
                          label="Can Edit Posts"
                        ></v-switch>
                      </v-col>
                      <v-col cols="6" sm="12" md="6">
                        <v-switch
                          v-model="editedItem.media_uploading_enabled"
                          label="Can Upload New Content"
                        ></v-switch>
                      </v-col>
                      <v-col cols="6" sm="12" md="6">
                        <v-switch
                          v-model="editedItem.enable_comments"
                          label="Comments Enabled"
                        ></v-switch>
                      </v-col>
                      <v-col cols="6" sm="12" md="6">
                        <v-switch
                          v-model="editedItem.enable_leaderboard"
                          label="Enable Leaderboard"
                        ></v-switch>
                      </v-col>
                      <v-col cols="6" sm="12" md="6">
                        <v-switch
                          v-model="editedItem.enable_post_display"
                          label="Enable Post Display"
                        ></v-switch>
                      </v-col>
                      <v-col cols="6" sm="12" md="6">
                        <v-text-field
                          v-model.number="editedItem.create_post_button_title"
                          label="Create a post button title"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6" sm="12" md="6">
                        <v-switch
                          v-model="editedItem.comment_section_in_video_modal"
                          label="Enable Video Modal Comments"
                        ></v-switch>
                      </v-col>
                      <v-col cols="6" sm="12" md="6">
                        <v-text-field
                          v-model.number="editedItem.layout_options"
                          label="Layout Option"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6" sm="12" md="6">
                        <v-text-field
                          v-model.number="editedItem.leaderboard_count"
                          label="Leaderboard Count"
                          type="number"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6" sm="12" md="6">
                        <v-text-field
                          v-model.number="editedItem.initial_post_count"
                          label="Initial Post Count"
                          type="number"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6" sm="12" md="6">
                        <v-text-field
                          v-model.trim="editedItem.leaderboard_title"
                          label="Leaderboard Title"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6" sm="12" md="6">
                        <v-select
                          v-model="editedItem.default_post_status"
                          :items="['approved', 'unapproved', 'deleted']"
                          label="Default Post Status"
                        ></v-select>
                      </v-col>
                      <v-col cols="6" sm="12" md="6">
                        <v-text-field
                          v-model.number="editedItem.max_post_count"
                          label="Max Posts Per Attendee"
                          type="number"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6" sm="12" md="6">
                        <v-text-field
                          v-model.trim="editedItem.social_feed_title"
                          label="Social Feed Title"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6" sm="12" md="6">
                        <v-text-field
                          v-model.number="editedItem.vote_count"
                          label="Vote Count"
                          type="number"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6" sm="12" md="6">
                        <v-text-field
                          v-model.trim="editedItem.vote_count_text"
                          label="Vote Count Text"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6" sm="12" md="6">
                        <v-switch
                          v-model="editedItem.vote_using_button"
                          label="Vote Using a button rather than icon"
                        ></v-switch>
                      </v-col>
                      <v-col cols="6" sm="12" md="6">
                        <v-switch
                          v-model="editedItem.show_like_count"
                          label="Show Like Count"
                        ></v-switch>
                      </v-col>
                      <v-col cols="12"> </v-col>
                      <v-col cols="12" sm="12" md="12">
                        <v-switch
                          v-model="editedItem.enable_point_system"
                          label="enable_point_system"
                        ></v-switch>
                      </v-col>
                      <v-row v-if="editedItem.enable_point_system === true">
                        <v-col cols="6" sm="12" md="6">
                          <v-text-field
                            v-model.number="editedItem.points_per_like"
                            label="Points Per Like"
                            type="number"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="6" sm="12" md="6">
                          <v-text-field
                            v-model.number="editedItem.points_per_like_cap"
                            label="Points Per Like Cap"
                            type="number"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="6" sm="12" md="6">
                          <v-text-field
                            v-model.number="editedItem.points_per_post"
                            label="Points Per Post"
                            type="number"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="6" sm="12" md="6">
                          <v-text-field
                            v-model.number="editedItem.points_per_photo_upload"
                            label="Points Per Photo Upload"
                            type="number"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="6" sm="12" md="6">
                          <v-text-field
                            v-model.number="
                              editedItem.points_for_receiving_like_cap
                            "
                            label="Cap on Receiving Points Per Like"
                            type="number"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="6" sm="12" md="6">
                          <v-text-field
                            v-model="editedItem.instructions"
                            label="Post Instructions"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="close"
                    >Cancel</v-btn
                  >
                  <v-btn color="blue darken-1" text @click="save">Save</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
          <router-link :to="'/games/socialfeed/' + item.url"
            ><v-icon small class="mr-2">mdi-pencil</v-icon></router-link
          >
          <!-- <v-icon small @click="deleteItem(item)">mdi-delete</v-icon> -->
        </template>
        <template v-slot:no-data>
          <v-btn color="primary" @click="fetch_locations">Refresh</v-btn>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
// import userDisplay from "@/components/user_display"
// import user_component from "@/components/user_component"
import * as validation from "@/modules/form_validation_functions";
import RoomSelector from "@/components/room_selector";

export default {
  name: "Social Feed",
  components: { RoomSelector },
  data: () => ({
    page: {
      title: "Social Feed"
    },
    breadcrumbs: [
      {
        text: "Games",
        disabled: true
      },
      {
        text: "Social Feed",
        disabled: true
      }
    ],
    rules: validation.rules,

    dialog: false,
    headers: [
      { text: "URL", value: "pretty_url" },
      { text: "Display Name", value: "display_name" },
      { text: "Actions", value: "actions", sortable: false }
    ],
    editedIndex: -1,
    editedItem: {},
    defaultItem: {
      can_create_posts: true, // has item
      editable_posts: true, // has field
      media_uploading_enabled: false, // has field
      enable_comments: true, // has field
      enable_leaderboard: true, // has field
      enable_post_display: true, // has field
      layout_option: 1, // has field
      leaderboard_count: 5, // has field
      leaderboard_title: "Leaderboard", // has field
      max_post_count: -1, // has field
      social_feed_title: "Social Feed",
      default_post_status: "approved",
      vote_count: 3,
      vote_count_text: "",
      vote_using_button: false,
      show_like_count: true,
      enable_point_system: false,
      points_per_like: 0,
      points_per_like_cap: 5,
      points_per_post: 0,
      points_per_photo_upload: 0,
      points_for_receiving_like_cap: 5,
      comment_section_in_video_modal: true,
      create_post_button_title: "Create a Post",
      instructions: ""
    },
    search: "",
    sort_key: "last_name",
    selected_index: -1,
    selected_user_for_edit: null,
    last_seen: null,
    leaderboard_layout_options: [1, 2, 3, 4],
    FBPathname: "social_feed"
  }),
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Location" : "Edit Location";
    },
    activeLocations() {
      let active = [];
      const locations = this.$store.state.locations;
      for (const location of locations) {
        if (
          this.FBPathname in location &&
          location[this.FBPathname]["enabled"] === true
        ) {
          active.push(location);
        }
      }
      return active;
    },
    allLocations() {
      return this.$store.state.locations;
    },

    location_list_key() {
      return this.$store.state.location_list_key;
    }
  },
  watch: {
    dialog(val) {
      val || this.close();
    }
  },
  filters: {},
  created() {
    this.initialize();
    this.fetch_locations();
  },

  methods: {
    initialize() {},

    editItem(item) {
      this.editedIndex = this.allLocations.indexOf(item);
      console.log("edited index: ", this.editedIndex);
      this.editedItem = Object.assign(
        {},
        this.defaultItem,
        item[this.FBPathname]
      );
      this.dialog = true;
    },

    close() {
      this.dialog = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },

    save() {
      if (this.editedIndex > -1) {
        console.log("saving an edited location");
        this.$store.dispatch("update_location", [
          {
            id: this.allLocations[this.editedIndex].url,
            payload: this.editedItem,
            target: this.FBPathname
          }
        ]);
      } else {
        console.log("saving a new location");

        this.$store.dispatch("update_location", {
          id: this.allLocations[this.editedIndex].url,
          payload: this.editedItem,
          target: this.FBPathname
        });
      }
      this.close();
    },

    fetch_locations() {
      console.log(this.sort_key);
      this.$store.dispatch("get_locations");
    }
  }
};
</script>

<style></style>
