<template>
  <v-dialog v-model="dialog" max-width="500px">
    <v-card>
      <v-card-title>
        <span class="headline">{{ title }}</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-row>
            <slot
              name="form_input"
              :internal_edit_item="internal_edit_item"
            ></slot>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
        <v-btn color="blue darken-1" text @click="save">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "Editor",
  data: () => ({
    dialog: false,
    internal_edit_item: {}
  }),
  computed: {},
  props: {
    editedItem: {
      type: Object,
      default: () => {}
    },
    title: {
      type: String
    },
    callback: {
      type: String,
      default: ""
    }
  },
  watch: {
    editedItem(newValue, oldValue) {
      console.log("got new val: ", newValue);
      this.internal_edit_item = Object.assign({}, newValue);
    }
  },
  filters: {},

  methods: {
    close() {
      setTimeout(() => {
        this.internal_edit_item = Object.assign({}, {});
      }, 300);
      this.dialog = false;
    },
    show() {
      this.dialog = true;
    },
    save() {
      if (this.callback !== "") {
        this.$store.dispatch(this.callback, this.internal_edit_item);
        this.close();
      } else {
        this.$emit("edited_item", this.internal_edit_item);
      }
    }
  }
};
</script>
