import * as fb from "../firebase";
// import faker from "faker";
function get_social_feed_collection(customer, social_feed_id) {
  const social_feed_collection = fb.db
    .collection("customers")
    .doc(customer)
    .collection("social_feed")
    .doc("posts")
    .collection(social_feed_id);
  return social_feed_collection;
}
function get_users_collection(customer) {
  const users_collection = fb.db
    .collection("customers")
    .doc(customer)
    .collection("users");
  return users_collection;
}

async function remove_votes_from_user(uid, social_feed_id, collectionRef) {
  let setData = {
    [social_feed_id]: { votes: [] }
  };
  try {
    const res = await collectionRef.doc(uid).set(setData, { merge: true });
    return true;
  } catch (error) {
    console.error(error);
    return false;
  }
}

async function remove_votes_from_all_users(customer, social_feed_id) {
  const user_collection = get_users_collection(customer);
  const all_users = await user_collection.get();
  for (let i = 0; i < 10; i++) {
    const uid = all_users.docs[i].id;
    try {
      const res = await remove_votes_from_user(
        uid,
        social_feed_id,
        user_collection
      );
    } catch (error) {
      console.error(error);
    }
  }
}

async function delete_single_post(doc_id, collection_ref) {
  try {
    const res = collection_ref.doc(doc_id).delete();
    console.log("Successfully deleted document, ", doc_id);
    return true;
  } catch (error) {
    console.error(error);
    return false;
  }
}

async function delete_posts(customer, social_feed_id) {
  const social_feed_collection = get_social_feed_collection(
    customer,
    social_feed_id
  );
  const all_posts = await social_feed_collection.get();
  const errors = [];
  for (let i = 0; i < all_posts.docs.length; i++) {
    const doc_id = all_posts.docs[i].id;
    const docVal = await delete_single_post(doc_id, social_feed_collection);
    if (!docVal) errors.push(doc_id);
  }
  console.log("Errors deleting posts", errors);
}

async function fetch_posts(customer, social_feed_id) {
  const social_feed_collection = get_social_feed_collection(
    customer,
    social_feed_id
  );
  try {
    const res = await social_feed_collection.get();
    const all_posts = res.docs.map((item, index) => {
      return { ...item.data(), id: item.id };
    });
    return all_posts;
  } catch (error) {
    throw "Error fetching all posts from customer " +
      customer +
      "  -  " +
      social_feed_id;
  }
}

// async function create_fake_posts(customer, social_feed_id, count) {
//   const social_feed_collection = get_social_feed_collection(
//     customer,
//     social_feed_id
//   );
//   for (let i = 0; i < count; i++) {

//     let new_post = {
//       first_name : faker.name.firstName(),
//       last_name : faker.name.lastName()
//     };
//     const new_doc = fb.db.push();
//   }
// }

export {
  get_social_feed_collection,
  remove_votes_from_user,
  remove_votes_from_all_users,
  delete_single_post,
  delete_posts,
  fetch_posts
};
