<template>
  <div>
    <BaseBreadcrumb
      :title="page.title"
      :icon="page.icon"
      :breadcrumbs="breadcrumbs"
    ></BaseBreadcrumb>
    <v-list-item-subtitle class="text-wrap"> </v-list-item-subtitle>
    <div class="mt-4">
      <v-card-title>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="activeLocations"
        sort-by="display_name"
        class="border"
        :search="search"
      >
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-toolbar-title> </v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-dialog v-model="dialog" max-width="500px">
              <template v-slot:activator="{ on }">
                <room-selector
                  :location-list="allLocations"
                  :activeSelection="activeLocations"
                  :target="FBPathname"
                  v-on="on"
                >
                </room-selector>
              </template>
              <v-card>
                <v-card-title>
                  <span class="headline">{{ formTitle }}</span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="6" sm="12" md="6">
                        <v-text-field
                          v-model.trim="editedItem.quiz_id"
                          label="Quiz ID"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6" sm="12" md="6">
                        <v-text-field
                          v-model.trim="editedItem.quiz_button_title"
                          label="Quiz Button Title"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6" sm="12" md="6">
                        <v-switch
                          v-model="editedItem.embedded_in_leaderboard"
                          label="Quiz is Embedded In Leaderboard"
                        ></v-switch>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="close"
                    >Cancel</v-btn
                  >
                  <v-btn color="blue darken-1" text @click="save">Save</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
          <!-- <v-icon small @click="deleteItem(item)">mdi-delete</v-icon> -->
        </template>
        <template v-slot:no-data>
          <v-btn color="primary" @click="fetch_locations">Refresh</v-btn>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
// import userDisplay from "@/components/user_display"
// import user_component from "@/components/user_component"
import * as validation from "@/modules/form_validation_functions";
import RoomSelector from "@/components/room_selector";

export default {
  name: "quiz_game",
  components: { RoomSelector },
  data: () => ({
    page: {
      title: "Quiz Game"
    },
    breadcrumbs: [
      {
        text: "Games",
        disabled: true
      },
      {
        text: "Quiz Game",
        disabled: true
      }
    ],
    rules: validation.rules,

    dialog: false,
    headers: [
      { text: "URL", value: "pretty_url" },
      { text: "Display Name", value: "display_name" },
      { text: "Actions", value: "actions", sortable: false }
    ],
    editedIndex: -1,
    editedItem: {},
    defaultItem: {
      quiz_id: "",
      embedded_in_leaderboard: false,
      quiz_button_title: "Quiz"
    },
    search: "",
    sort_key: "last_name",
    selected_index: -1,
    selected_user_for_edit: null,
    last_seen: null,
    leaderboard_layout_options: [1, 2, 3, 4],
    FBPathname: "quiz_game"
  }),
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Location" : "Edit Location";
    },
    activeLocations() {
      let active = [];
      const locations = this.$store.state.locations;
      for (const location of locations) {
        if (
          this.FBPathname in location &&
          location[this.FBPathname]["enabled"] === true
        ) {
          active.push(location);
        }
      }
      return active;
    },
    allLocations() {
      return this.$store.state.locations;
    },

    location_list_key() {
      return this.$store.state.location_list_key;
    }
  },
  watch: {
    dialog(val) {
      val || this.close();
    }
  },
  filters: {},
  created() {
    this.initialize();
    this.fetch_locations();
  },

  methods: {
    initialize() {},

    editItem(item) {
      this.editedIndex = this.allLocations.indexOf(item);
      console.log("edited index: ", this.editedIndex);
      this.editedItem = Object.assign(
        {},
        this.defaultItem,
        item[this.FBPathname]
      );
      this.dialog = true;
    },

    close() {
      this.dialog = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },

    save() {
      if (this.editedIndex > -1) {
        console.log("saving an edited location");
        this.$store.dispatch("update_location", [
          {
            id: this.allLocations[this.editedIndex].url,
            payload: this.editedItem,
            target: this.FBPathname
          }
        ]);
      } else {
        console.log("saving a new location");

        this.$store.dispatch("update_location", {
          id: this.allLocations[this.editedIndex].url,
          payload: this.editedItem,
          target: this.FBPathname
        });
      }
      this.close();
    },

    fetch_locations() {
      console.log(this.sort_key);
      this.$store.dispatch("get_locations");
    }
  }
};
</script>

<style></style>
