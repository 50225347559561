var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('BaseBreadcrumb',{attrs:{"title":_vm.page.title,"icon":_vm.page.icon,"breadcrumbs":_vm.breadcrumbs}}),_c('v-list-item-subtitle',{staticClass:"text-wrap"}),_c('div',{staticClass:"mt-4"},[_c('v-card-title',[_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"border",attrs:{"headers":_vm.headers,"items":_vm.activeLocations,"sort-by":"display_name","search":_vm.search},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-toolbar-title'),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":_vm.max_widths[_vm.tab]},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('room-selector',_vm._g({attrs:{"location-list":_vm.allLocations,"activeSelection":_vm.activeLocations,"target":_vm.FBPathname}},on))]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-card-text',[_c('v-container',[_c('v-tabs',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',[_vm._v("Game Settings")]),_c('v-tab',[_vm._v("Clip Editor")])],1),_c('v-tabs-slider',{attrs:{"color":"yellow"}}),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',[_c('v-row',[_c('v-col',{attrs:{"cols":"6","sm":"12","md":"6"}},[_c('v-switch',{attrs:{"label":"Game Enabled"},model:{value:(_vm.editedItem.enabled),callback:function ($$v) {_vm.$set(_vm.editedItem, "enabled", $$v)},expression:"editedItem.enabled"}})],1),_c('v-col',{attrs:{"cols":"6","sm":"12","md":"6"}},[_c('v-btn',{attrs:{"elevation":"2"},on:{"click":_vm.saveData}},[_vm._v("Download Analytics")])],1),_c('v-col',{attrs:{"cols":"6","sm":"12","md":"6"}},[_c('v-btn',{attrs:{"elevation":"2"},on:{"click":function($event){_vm.open_editor = !_vm.open_editor}}},[_vm._v(_vm._s(!_vm.open_editor ? "Open" : "Close")+" Clip Edtior")])],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-card',{staticClass:"mx-auto justify-space-between",attrs:{"tile":""}},[_c('v-btn',{attrs:{"elevation":"2"},on:{"click":_vm.add_reaction}},[_vm._v("Add Reaction")]),_vm._l((_vm.editedItem.reactions),function(item,i){return _c('v-list-item',{key:i},[_c('v-list-item-content',[_c('v-list-item-title',[_c('v-row',[_c('v-col',{attrs:{"cols":"2"}},[_c('v-checkbox',{attrs:{"label":"Momentary"},model:{value:(
                                          _vm.editedItem.reactions[i].momentary
                                        ),callback:function ($$v) {_vm.$set(_vm.editedItem.reactions[i], "momentary", $$v)},expression:"\n                                          editedItem.reactions[i].momentary\n                                        "}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"label":"Reaction ID"},model:{value:(_vm.editedItem.reactions[i].id),callback:function ($$v) {_vm.$set(_vm.editedItem.reactions[i], "id", $$v)},expression:"editedItem.reactions[i].id"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"label":"Reaction Icon URL"},model:{value:(
                                          _vm.editedItem.reactions[i].icon_url
                                        ),callback:function ($$v) {_vm.$set(_vm.editedItem.reactions[i], "icon_url", $$v)},expression:"\n                                          editedItem.reactions[i].icon_url\n                                        "}})],1),_c('v-col',{attrs:{"cols":"1"}},[_c('v-btn',{attrs:{"elevation":"2"},on:{"click":function($event){return _vm.remove_reaction(i)}}},[_vm._v("X")])],1)],1)],1)],1)],1)})],2)],1),_c('v-col',{attrs:{"cols":"6","sm":"12","md":"6"}},[_c('v-text-field',{attrs:{"label":"Database URL"},model:{value:(_vm.editedItem.database_url),callback:function ($$v) {_vm.$set(_vm.editedItem, "database_url", $$v)},expression:"editedItem.database_url"}})],1)],1)],1),_c('v-tab-item',[_c('reactionSystemEditor',{attrs:{"location":_vm.editingLocation,"editedItem":_vm.editedItem},on:{"reaction-clip-saved":_vm.reaction_clip_saved}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.close}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.save}},[_vm._v("Save")])],1)],1)],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
                                        var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v("mdi-pencil")])]}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.fetch_locations}},[_vm._v("Refresh")])]},proxy:true}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }