<template>
  <div>
    <v-list-item-subtitle class="text-wrap"></v-list-item-subtitle>
    <div class="mt-4">
      <v-divider></v-divider>
      <!-- <v-row>
        <v-col>
          <v-text-field
            v-model="email_search"
            append-icon="mdi-magnify"
            label="Search For UID from Email"
            single-line
            hide-details
          ></v-text-field>
          <v-btn color="primary" dark class="mb-2" @click="search_user_by_email"
            >Search</v-btn
          >
        </v-col>
        <v-col>
          <v-text-field
            v-model="last_name_search"
            append-icon="mdi-magnify"
            label="Search For UID from Last Name"
            single-line
            hide-details
          ></v-text-field>
          <v-btn
            color="primary"
            dark
            class="mb-2"
            @click="search_user_by_last_name"
            >Search</v-btn
          >
        </v-col>
      </v-row> -->
      <AdminLayoutTable
        table_title="Groups"
        :headers="headerProps"
        :items="items"
        v-on:edit_item="edit_item"
        v-on:delete_item="delete_item"
        item_key="user_data.email"
      >
        <template v-slot:importer>
          <csv-importer-view
            :import_headers="import_headers"
            button-title="Create Group"
            item-type="Group"
            v-on:save-import="saveImport"
            :dynamicTyping="true"
          >
            <template v-slot:singleItemImporter>
              <SingleUserCreator></SingleUserCreator>
            </template>
          </csv-importer-view>
        </template>
      </AdminLayoutTable>
      <generic-editor
        ref="generic_editor"
        :editedItem="selected_group_for_edit"
        title="Edit Group"
        callback="edit_group"
      >
        <template v-slot:form_input="form_input">
          <v-col cols="6" sm="12" md="6">
            <v-text-field
              v-model="form_input.internal_edit_item.display_name"
              label="Group Display Name"
            ></v-text-field>
          </v-col>
          <v-col cols="6" sm="12" md="6">
            <v-text-field
              v-model="form_input.internal_edit_item.multiplier"
              label="Group Multiplier"
            ></v-text-field>
          </v-col>
        </template>
      </generic-editor>
    </div>
  </div>
</template>

<script>
import AdminLayoutTable from "@/components/AdminLayoutTable";
import group_editor from "@/components/GroupEditorVT";
import CSVImporterView from "@/components/csvData/CSVImporterView";
import point_creator_vt from "@/components/point_creator_vt";
import user_creator from "@/components/user_creator";
import SingleUserCreator from "@/components/SingleUserCreator";
import generic_editor from "@/components/generic_editor";
export default {
  //headers are supplied as a list of objects
  //Text is the display name/text is mapped to the item(s) key { text: "name", value: "name" },

  name: "AdminUserLayout",
  components: {
    AdminLayoutTable,
    "csv-importer-view": CSVImporterView,
    "generic-editor": generic_editor,
    SingleUserCreator
    // point_creator_vt,
    // user_creator
  },
  props: {},
  data() {
    return {
      selected: [],
      dialog: false,
      edit_dialog: false,
      formTitle: "My form title",
      tab: "tab data",
      email_search: "",
      last_name_search: "",
      headerProps: [
        { text: "Group ID", value: "groupId" },
        { text: "Display Name", value: "display_name" },
        { text: "Multiplier", value: "multiplier" },
        { text: "Actions", value: "actions", sortable: false }
      ],
      search: "",
      editedItem: {},
      items_per_page: 50,
      sort_key: "last_name",
      selected_index: -1,
      selected_group_for_edit: null,
      last_seen: null,
      import_headers: [
        {
          slug: "group_id",
          required: true,
          tooltip: `e.g. central
          This is the group's identity, not the display name. Must not include spaces.
          `
        },
        {
          slug: "display_name",
          required: true,
          tooltip: `e.g. Central Chumps
          The name that will show up on the leaderboard.
          `
        }
      ]
    };
  },
  methods: {
    fetch_groups() {
      // console.log(this.sort_key);
      this.$store.dispatch("fetch_groups", {
        orderBy: this.sort_key,
        items_per_page: this.items_per_page
      });
    },
    close() {},
    editItem() {
      console.log("Running from parent");
    },
    save() {},
    process_data() {},
    process_import() {},
    initialize() {},
    edit_item(groupObj) {
      console.log("running edited item", groupObj);
      this.selected_group_for_edit = Object.assign({}, groupObj);
      this.$nextTick(function() {
        this.$refs.generic_editor.show();
      });
    },
    saveUser() {},
    delete_item() {},
    saveBatchUsers() {},
    saveImport(csvData) {
      console.log(csvData);
      let final_csv_data = csvData.map(item => {
        return {
          groupId: item.group_id,
          group_score: 0
        };
      });
      // this.$store.dispatch("create_groups", csvData);
    },
    search_user_by_email() {
      this.$store.dispatch("get_user_by_email", this.email_search);
    },
    search_user_by_last_name() {
      this.$store.dispatch("get_user_by_last_name", this.last_name_search);
    },
    edited_item(data) {}
  },
  computed: {
    allItems() {
      return this.$store.state.attendee_groups;
    },
    items() {
      return this.$store.state.attendee_groups;
    }
  },
  created() {
    this.fetch_groups();
  }
};
</script>
<style scoped></style>
