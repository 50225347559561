<template>
  <div>
    <form>
      <v-row align="center">
        <v-col class="d-flex" cols="12" sm="6">
          <p>{{ location.display_name }}</p>
        </v-col>
        <v-col cols="6" sm="6" md="6">
          <v-text-field label="Clip Name" v-model="clip.name"></v-text-field>
        </v-col>
        <v-col cols="6" sm="6" md="6">
          <v-text-field label="Vimeo ID" v-model="clip.vimeo_id"></v-text-field>
        </v-col>
        <v-col cols="6" sm="6" md="6">
          <v-text-field
            type="number"
            label="Resolution"
            v-model="clip.resolution"
          ></v-text-field>
        </v-col>
        <v-col cols="6" sm="6" md="6">
          <v-text-field
            label="Timezone"
            v-model="clip.resolution"
          ></v-text-field>
        </v-col>
        <v-col cols="2" sm="2" md="2">
          <v-btn @click="open_time_selector = !open_time_selector">{{
            !open_time_selector ? "Edit Time" : "Close"
          }}</v-btn>
        </v-col>
        <v-col cols="5" sm="5" md="5">
          <h4 class="time-label">Start Time:{{ clip.start }}</h4>
        </v-col>
        <v-col cols="5" sm="5" md="5">
          <h4 class="time-label">End Time:{{ clip.end }}</h4>
        </v-col>
      </v-row>
    </form>
    <generic-scheduler
      v-if="open_time_selector"
      @time_change="time_change"
    ></generic-scheduler>
    <v-card class="mx-auto" max-width="1000" tile>
      <v-row>
        <v-col cols="6">
          <v-list dense>
            <v-subheader>Reactions</v-subheader>
            <v-list-item-group v-model="selectedItem" color="primary">
              <v-list-item
                v-for="(reaction, i) in this.editedItem.reactions"
                :key="i"
              >
                <v-list-item-icon>
                  <v-icon>mdi-emoticon</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-text="reaction"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-col>
        <v-col
          cols="6"
          sm="6"
          md="6"
          v-if="clip.config[editedItem.reactions[selectedItem]]"
        >
          <v-subheader>Configuration</v-subheader>

          <v-text-field
            label="Display Name"
            v-model="
              clip.config[editedItem.reactions[selectedItem]].display_name
            "
          ></v-text-field>
          <v-text-field
            label="Color"
            v-model="clip.config[editedItem.reactions[selectedItem]].color"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
// import userDisplay from "@/components/user_display"
import Generic_scheduler from "./generic_scheduler.vue";
import { mdiEmoticon } from "@mdi/js";
import bus from "@/event_bus.js";

export default {
  name: "reactionSystemEditor",
  components: {
    genericScheduler: Generic_scheduler
  },
  props: {
    location: {
      type: Object,
      default: () => {}
    },
    editedItem: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    page: {
      title: "Reaction System"
    },
    breadcrumbs: [
      {
        text: "Games",
        disabled: true
      },
      {
        text: "Reactions",
        disabled: true
      },
      {
        text: "Data",
        disabled: false
      }
    ],
    open_time_selector: false,
    room_selection: "",
    raw_time: [],
    clip: {
      start: null,
      end: null,
      location: "",
      name: "",
      vimeo_id: "",
      resolution: 60,
      display_timezone: "America/New_York",
      config: {}
    },
    reaction_list: [],
    selectedItem: ""
  }),
  computed: {
    select_options: function() {
      return this.activeLocations.map(item => item.display_name);
    }
  },

  filters: {},
  created() {
    this.$set(this.clip, "location", this.location.url);
    this.editedItem.reactions.forEach(item => {
      this.$set(this.clip.config, item, {
        display_name: "",
        color: "red"
      });
    });
    let self = this;
    bus.$on("reaction-clip-save-request", () => {
      self.$emit("reaction-clip-saved", self.clip);
    });
  },

  methods: {
    time_change(data) {
      this.$set(this.clip, "start", data.date + " " + data.start);
      this.$set(this.clip, "end", data.date + " " + data.end);
    }
  }
};
</script>

<style></style>
